import {Injectable} from '@angular/core';
import {Platform} from '@angular/cdk/platform';

@Injectable({
    providedIn: 'root'
})
export class MobileService {
    private _isMobile = this.platform.ANDROID || this.platform.IOS;

    get mobile(): boolean {
        return this._isMobile;
    }

    constructor(private platform: Platform) {
    }
}
